import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import * as EgovNet from 'api/egovFetch';
import URL from 'constants/url';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function EgovMain(props) {
    console.group("EgovMain");
    console.log("[Start] EgovMain ------------------------------");
    console.log("EgovMain [props] : ", props);
 
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,         // 한번에 1개의 슬라이드를 보여줌
        centerMode: true,        // 중앙에 슬라이드를 배치하고 좌우에 일부 이미지가 보이도록 설정
        centerPadding: "15%",    // 양쪽에 25%씩 보이도록 설정
        autoplay: true,
        autoplaySpeed: 3000,
      };

    const location = useLocation();
    console.log("EgovMain [location] : ", location);

    const [activeSlide, setActiveSlide] = useState(0);
	// eslint-disable-next-line no-unused-vars
    const [noticeBoard, setNoticeBoard] = useState();
	// eslint-disable-next-line no-unused-vars
    const [gallaryBoard, setGallaryBoard] = useState();
    const [noticeListTag, setNoticeListTag] = useState();
    const [gallaryListTag, setGallaryListTag] = useState();

    const retrieveList = useCallback(() => {
        console.groupCollapsed("EgovMain.retrieveList()");

        const retrieveListURL = '/mainPage';
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-type': 'application/json'
            }
        }

        EgovNet.requestFetch(retrieveListURL,
            requestOptions,
            (resp) => {

                setNoticeBoard(resp.result.notiList);
                setGallaryBoard(resp.result.galList);

                let mutNotiListTag = [];
                mutNotiListTag.push(<li key="0">검색된 결과가 없습니다.</li>); // 게시판 목록 초기값

                // 리스트 항목 구성
                resp.result.notiList.forEach(function (item, index) {
                    if (index === 0) mutNotiListTag = []; // 목록 초기화
                    mutNotiListTag.push(
                        <li key={item.nttId}>
                            <Link
                                to={{pathname: URL.INFORM_NOTICE_DETAIL}}
                                state={{
                                    nttId: item.nttId,
                                    bbsId: item.bbsId
                                }}
                            >
                                {item.nttSj}
                                <span>{item.frstRegisterPnttm}</span>
                            </Link>
                        </li>
                    );
                });
                setNoticeListTag(mutNotiListTag);

                let mutGallaryListTag = [];
                mutGallaryListTag.push(<li key="0">검색된 결과가 없습니다.</li>); // 게시판 목록 초기값

                // 리스트 항목 구성
                resp.result.galList.forEach(function (item, index) {
                    if (index === 0) mutGallaryListTag = []; // 목록 초기화
                    mutGallaryListTag.push(
                        <li key={item.nttId}>
                            <Link
                                to={{pathname: URL.INFORM_GALLERY_DETAIL}}
                                state={{
                                    nttId: item.nttId,
                                    bbsId: item.bbsId
                                }}
                            >
                                {item.nttSj}
                                <span>{item.frstRegisterPnttm}</span>
                            </Link>
                        </li>
                    );
                });
                setGallaryListTag(mutGallaryListTag);
            },
            function (resp) {
                console.log("err response : ", resp);
            }
        );
        console.groupEnd("EgovMain.retrieveList()");
    },[]);

    useEffect(() => {
        retrieveList();
    }, [retrieveList]);

    console.log("------------------------------EgovMain [End]");
    console.groupEnd("EgovMain");

    return (
        <div className="container P_MAIN">
           <div style={{ width: "100%", height: "auto", overflow: "hidden"}}>
                
                <Slider {...settings}>
                  <div style={{ position: "relative" }}>
                    <Link to="https://kpoplove58.tistory.com/">
                    <img src="/assets/images/slider_images/001.png" alt="Slide 1" style={{ width: "100%", objectFit: "cover" , aspectRatio: "16/9"}} />
                    </Link> 
                      {/* <Link to="https://blog.naver.com/sangnokdadol">
                      <div className="slide-link1"
                            style={{
                            position: "absolute",
                            top: "67%",
                            left: "60.3%",
                            transform: "translate(-50%, -50%)",
                            background: "rgba(255, 255, 255, 0.3)",
                            color: "#595959",
                            borderRadius: "10px",
                            textAlign: "center",
                            }}
                        >
                            다함께돌봄센터 (클릭)
                        </div>
                        </Link> */}
                  </div>
                  <div style={{ position: "relative" }}>
                      <Link to="https://kpoplove58.tistory.com/">
                      <img src="/assets/images/slider_images/002.png" alt="Slide 2" style={{ width: "100%", objectFit: "cover" , aspectRatio: "16/9" }}/>
                      </Link>
                      {/* <Link to="https://kpoplove58.tistory.com/">
                      <div className="slide-link2"
                            style={{
                            position: "absolute",
                            top: "47.8%",
                            left: "65.7%",
                            transform: "translate(-50%, -50%)",
                            background: "rgba(255, 255, 255, 0.3)",
                            color: "#595959",
                            borderRadius: "10px",
                            textAlign: "center",
                            }}
                        >
                            사랑나눔지역아동센터 (클릭)
                        </div></Link> */}
                  </div>
                  <div style={{ position: "relative" }}>
                      <Link to="https://blog.naver.com/sangnokdadol">
                      <img src="/assets/images/slider_images/003.png" alt="Slide 3" style={{ width: "100%", objectFit: "cover" , aspectRatio: "16/9"}}/>
                      </Link>
                      {/* <Link to="https://kpoplove58.tistory.com/">
                      <div className="slide-link3"
                            style={{
                            position: "absolute",
                            top: "40%",
                            left: "43.7%",
                            transform: "translate(-50%, -50%)",
                            background: "rgba(255, 255, 255, 0.3)",
                            color: "#595959",
                            borderRadius: "10px",
                            textAlign: "center",
                            }}
                        >
                            아가페지역아동센터 (클릭)
                        </div></Link> */}
                  </div>
                  
                </Slider>
            </div> 
              
  
            <div className="c_wrap">
                <div className="colbox">
                    <div className="left_col">
                        <Link to={URL.SUPPORT_APPLY}>
                            <img src="/assets/images/bws_main_square2.png" alt="아름다운 세상 법인 후원 포스터." style={{width: "100%", height: "530px", objectFit: "cover" }} />
                        </Link>
                    </div>

                    <div className="right_col">
                        <div className="mini_board">
                            <ul className="tab">
                                <li><a href="#공지사항" className="on">공지사항</a></li>
                                <li><a href="#갤러리">갤러리</a></li>
                            </ul>
                            <div className="list">
                                <div className="notice">
                                    <h2 className="blind">공지사항</h2>
                                    <ul>
                                        {noticeListTag}
                                    </ul>
                                    <Link to={URL.INFORM_NOTICE} className="more">더보기</Link>
                                </div>

                                <div className="gallary">
                                    <h2 className="blind">갤러리</h2>
                                    <ul>
                                        {gallaryListTag}
                                    </ul>
                                    <Link to={URL.INFORM_GALLERY} className="more">더보기</Link>
                                </div>
                            </div>
                        </div>

                        <div className="banner">
                            <Link to={URL.SUPPORT_DOWNLOAD} className="bn1">
                                <strong>운영자료</strong>
                                <span>다양한 자료를<br />다운로드 받으실 수 있습니다.</span>
                            </Link>
                            <Link to={URL.ABOUT} className="bn2">
                                <strong>법인소개</strong>
                                <span>아름다운세상 협동조합의<br />연혁 등의 정보를 제공합니다.</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="banner_bot">
                    <div className="b1">
                        <div>
                            <h2>주요사업 소개</h2>
                            <p>아름다운 세상 협동조합이 제공하는
                                주요 사업을 소개합니다.</p>
                        </div>
                        <Link to={URL.INTRO_WORKS}>자세히 보기</Link>
                    </div>
                    <div className="b2">
                        <div>
                            <h2>기관 소개</h2>
                            <p>아름다운 세상 협동조합의
                            하위 기관들이 소개된 페이지입니다.</p>
                        </div>
                        <Link to={URL.INTRO_SERVICE}>자세히 보기</Link>
                    </div>
                    <div className="b3">
                        <div>
                            <h2>후원 문의</h2>
                            <p>안산의 아동센터에 <br />
                                후원을 <br />
                                신청 하실 수 있습니다.</p>
                        </div>
                        <Link to={URL.SUPPORT_APPLY}>자세히 보기</Link>
                    </div>
                    <div className="b4">
                        <div>
                            <h2>찾아오시는 길</h2>
                            <p>아름다운 세상 협동조합에<br />
                                오시는 길을 안내해드립니다.</p>
                        </div>
                        <Link to={URL.INFORM}>자세히 보기</Link>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default EgovMain;