import React from 'react';

function EgovInfoPopup(){
    return (
        <div className="wrap_pop TEMPLATE_INTRO">
        <div className="pop_inner">
            <div className="pop_header">
                <h1>아름다운세상 소개</h1>
                <button className="btn close" type="button">닫기</button>
            </div>
    
            <div className="pop_container">
                <ul className="list_1">
                    <li>아름다운세상을 소개합니다.</li>
                    <li>관리자로 로그인하면 관리자용 메뉴를 추가로 사용할 수 있습니다.</li>
                    <li>법인소개, 기관소개 및 사업계획서, 고객지원 메뉴는 구성을 위한 기능이 제공됩니다.</li>
                </ul>
                <div className="img">
                    <img src="/assets/images/img_template_intro.png" alt=""/>
                </div>

            </div>
        </div>
    </div>
    )
}

export default EgovInfoPopup;