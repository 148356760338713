// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* CSS (slider.css) */
.slide-link {
    font-size: 18px;
    padding: 15px 25px;
  }
  
  @media screen and (max-width: 768px) {
    .slide-link1 {
      font-size: 14px; /* 글씨 크기 축소 */
      padding: 10px 20px; /* 여백 축소 */
    }
    .slide-link2 {
      font-size: 14px; /* 글씨 크기 축소 */
      padding: 10px 20px; /* 여백 축소 */
    }
    .slide-link3 {
      font-size: 14px; /* 글씨 크기 축소 */
      padding: 10px 20px; /* 여백 축소 */
    }
  }
  
  @media screen and (max-width: 480px) {
    .slide-link1 {
      font-size: 12px; /* 글씨 크기 더 축소 */
      padding: 8px 15px; /* 여백 축소 */
    }
    .slide-link2 {
      font-size: 12px; /* 글씨 크기 더 축소 */
      padding: 8px 15px; /* 여백 축소 */
    }
    .slide-link3 {
      font-size: 12px; /* 글씨 크기 더 축소 */
      padding: 8px 15px; /* 여백 축소 */
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/slider.css"],"names":[],"mappings":";AACA,qBAAqB;AACrB;IACI,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE;MACE,eAAe,EAAE,aAAa;MAC9B,kBAAkB,EAAE,UAAU;IAChC;IACA;MACE,eAAe,EAAE,aAAa;MAC9B,kBAAkB,EAAE,UAAU;IAChC;IACA;MACE,eAAe,EAAE,aAAa;MAC9B,kBAAkB,EAAE,UAAU;IAChC;EACF;;EAEA;IACE;MACE,eAAe,EAAE,eAAe;MAChC,iBAAiB,EAAE,UAAU;IAC/B;IACA;MACE,eAAe,EAAE,eAAe;MAChC,iBAAiB,EAAE,UAAU;IAC/B;IACA;MACE,eAAe,EAAE,eAAe;MAChC,iBAAiB,EAAE,UAAU;IAC/B;EACF","sourcesContent":["\n/* CSS (slider.css) */\n.slide-link {\n    font-size: 18px;\n    padding: 15px 25px;\n  }\n  \n  @media screen and (max-width: 768px) {\n    .slide-link1 {\n      font-size: 14px; /* 글씨 크기 축소 */\n      padding: 10px 20px; /* 여백 축소 */\n    }\n    .slide-link2 {\n      font-size: 14px; /* 글씨 크기 축소 */\n      padding: 10px 20px; /* 여백 축소 */\n    }\n    .slide-link3 {\n      font-size: 14px; /* 글씨 크기 축소 */\n      padding: 10px 20px; /* 여백 축소 */\n    }\n  }\n  \n  @media screen and (max-width: 480px) {\n    .slide-link1 {\n      font-size: 12px; /* 글씨 크기 더 축소 */\n      padding: 8px 15px; /* 여백 축소 */\n    }\n    .slide-link2 {\n      font-size: 12px; /* 글씨 크기 더 축소 */\n      padding: 8px 15px; /* 여백 축소 */\n    }\n    .slide-link3 {\n      font-size: 12px; /* 글씨 크기 더 축소 */\n      padding: 8px 15px; /* 여백 축소 */\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
