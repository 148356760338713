import React from 'react';

import { NavLink } from 'react-router-dom';
import URL from 'constants/url';

function EgovLeftNavIntro() {
    
    return (
        <div className="nav">
            <div className="inner">
                <h2>기관소개 및 사업계획서</h2>
                <ul className="menu4">
                    <li><NavLink to={URL.INTRO_SERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>기관 소개</NavLink></li>
                    <li><NavLink to="https://kpoplove58.tistory.com/" className={({ isActive }) => (isActive ? "cur" : "")}>아가페지역아동센터</NavLink></li>
                    <li><NavLink to="https://kpoplove58.tistory.com/" className={({ isActive }) => (isActive ? "cur" : "")}>사랑나눔지역아동센터</NavLink></li>
                    <li><NavLink to="https://blog.naver.com/sangnokdadol" className={({ isActive }) => (isActive ? "cur" : "")}>상록가치키움터</NavLink></li>
                </ul>
            </div>
        </div>
    );
}

export default EgovLeftNavIntro;