// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* EgovAdminNoticeEdit.css */
.ql-container {
    height: calc(100% - 42px); /* 툴바 높이만큼 제외 (툴바가 42px인 경우) */
}

.ql-editor {
    min-height: 300px; /* 최소 높이 설정 */
    max-height: 100%;  /* 부모의 100% 높이에 맞춤 */
    overflow-y: auto;  /* 내용이 넘칠 경우 스크롤 */
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/notice/EgovAdminNoticeEdit.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,yBAAyB,EAAE,8BAA8B;AAC7D;;AAEA;IACI,iBAAiB,EAAE,aAAa;IAChC,gBAAgB,GAAG,oBAAoB;IACvC,gBAAgB,GAAG,kBAAkB;AACzC","sourcesContent":["/* EgovAdminNoticeEdit.css */\n.ql-container {\n    height: calc(100% - 42px); /* 툴바 높이만큼 제외 (툴바가 42px인 경우) */\n}\n\n.ql-editor {\n    min-height: 300px; /* 최소 높이 설정 */\n    max-height: 100%;  /* 부모의 100% 높이에 맞춤 */\n    overflow-y: auto;  /* 내용이 넘칠 경우 스크롤 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
