import React from 'react';
import { Link } from 'react-router-dom';

function EgovFooter() {
    return (
        <div className="footer">
            <div className="inner">
                <h1 style={{paddingTop: '40px'}}>
                    <Link to="">
{/*                         <img className="w" src="/assets/images/logo_footer_w.png" alt="" /> */}
                        <img className="w" src="/assets/images/bws_new_logo_w.png" alt="" />
                        <img className="m"style={{ maxHeight: '100%', height: '50px' }} src="/assets/images/bws_new_logo_m.png" alt="" />
                    </Link>
                </h1>
                <div className="info">
                    <p>
                        대표문의메일 : myk0584@daum.net  <span className="m_hide">|</span><br className="m_show" />  대표전화 : 031-417-8122<br />
                        (우)15324 경기도 안산시 상록구 일동로 15 2층
                    </p>
                    <p className="copy">Copyright © 2024 아름다운세상 사회적협동조합. All Rights Reserved.</p>
                </div>
                
                <div className="right_col">
                    <Link to="https://www.mohw.go.kr/kor/">
                        <img className="w" src="/assets/images/banner_w1.png" alt="" />
                        <img className="m" src="/assets/images/banner_m1.png" alt="" />
                    </Link>
                    <Link to="https://kaccc.org/">
                        <img className="w" src="/assets/images/banner_w2.png" alt="" />
                        <img className="m" src="/assets/images/banner_m2.png" alt="" />
                    </Link>
                    <Link to="https://www.ncrc.or.kr/ncrc/main.do">
                        <img className="w" src="/assets/images/banner_w3.png" alt="" />
                        <img className="m" src="/assets/images/banner_m3.png" alt="" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default EgovFooter;