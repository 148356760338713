import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import * as EgovNet from 'api/egovFetch';

import URL from 'constants/url';
import CODE from 'constants/code';
import { getSessionItem, setSessionItem } from 'utils/storage';

function EgovHeader() {
    console.group("EgovHeader");
    console.log("[Start] EgovHeader ------------------------------");

    const sessionUser = getSessionItem('loginUser');
    const sessionUserId = sessionUser?.id;
    const sessionUserName = sessionUser?.name;
    const sessionUserSe = sessionUser?.userSe;

    const navigate = useNavigate();

    const logInHandler = () => { // 로그인 정보 없을 시
        navigate(URL.LOGIN);
		// PC와 Mobile 열린메뉴 닫기
		document.querySelector('.all_menu.WEB').classList.add('closed');
        document.querySelector('.btnAllMenu').classList.remove('active');
        document.querySelector('.btnAllMenu').title = '전체메뉴 닫힘';
		document.querySelector('.all_menu.Mobile').classList.add('closed');
    }
    const logOutHandler = () => {// 로그인 정보 존재할 때
        const logOutUrl = '/auth/logout';
        const requestOptions = {
            headers: {
                'Content-type': 'application/json',
            },
            credentials: 'include',
        }
        EgovNet.requestFetch(logOutUrl, requestOptions,
            function (resp) {
                console.log("===>>> logout resp= ", resp);
                if (parseInt(resp.resultCode) === parseInt(CODE.RCV_SUCCESS)) {
                    //onChangeLogin({ loginVO: {} });
                    setSessionItem('loginUser', {"id":""});
                    setSessionItem('jToken', null);
                    window.alert("로그아웃되었습니다!");
                    navigate(URL.MAIN);
					// PC와 Mobile 열린메뉴 닫기
					document.querySelector('.all_menu.WEB').classList.add('closed');
	                document.querySelector('.btnAllMenu').classList.remove('active');
	                document.querySelector('.btnAllMenu').title = '전체메뉴 닫힘';
					document.querySelector('.all_menu.Mobile').classList.add('closed');
                }
            }
        );
    }

    console.log("------------------------------EgovHeader [End]");
    console.groupEnd("EgovHeader");

    return (
        // <!-- header -->
        <div className="header">
            <div className="inner">
                <h1 className="logo" style={{ maxHeight: '100%', height: 'auto' ,marginTop: '16px'}} >
                    <Link to={URL.MAIN} className="w" style={{ maxHeight: '100%', height: 'auto' }} ><img src="/assets/images/bws_header_logo_w.png" alt="아름다운세상 홈페이지" style={{ maxHeight: '100%', height: 'auto' }}  /></Link>
                    <Link to={URL.MAIN} className="m"style={{ maxHeight: '100%', height: '50px' }}><img src="/assets/images/bws_new_logo_m.png" alt="아름다운세상 홈페이지" style={{ maxHeight: '100%', height: 'auto' }}  /></Link>
                </h1>

                <div className="gnb">
                    <h2 className="blind">주메뉴</h2>
                    <ul>
                        <li><NavLink to={URL.ABOUT} className={({ isActive }) => (isActive ? "cur" : "")}>법인소개</NavLink></li>
                        <li><NavLink to={URL.INTRO_SERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>기관소개 및 사업계획서</NavLink></li>
                        <li><NavLink to={URL.SUPPORT} className={({ isActive }) => (isActive ? "cur" : "")}>고객지원</NavLink></li>
                        <li><NavLink to={URL.INFORM_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")}>알림마당</NavLink></li>
                        {sessionUserId &&
                            <li><NavLink to={URL.ADMIN} className={({ isActive }) => (isActive ? "cur" : "")}>사이트관리</NavLink></li>
                        }
                    </ul>
                </div>

                {/* <!-- PC web에서 보여지는 영역 --> */}
                <div className="user_info">
                    {/* 로그아웃 : 로그인 정보 있을때 */}
                    {sessionUserId &&
                        <>
                            <span className="person">{sessionUserName} </span> 님이, 관리자로 로그인하셨습니다.
                            <button onClick={logOutHandler} className="btn">로그아웃</button>
                        </>
                    }
                    {/* 로그인 : 로그인 정보 없을 때 */}
                    {!sessionUserId &&
                        <button onClick={logInHandler} className="btn login">로그인</button>
                    }
                </div>
                {/* <!--// PC web에서 보여지는 영역 --> */}

                {/* <!-- right area --> */}
                <div className="right_a">
                    <button type="button" className="btn btnAllMenu" title="전체메뉴 닫힘">전체메뉴</button>
                    <button type="button" className="btn mobile btnAllMenuM" title="전체메뉴 닫힘">전체메뉴</button>
                </div>
            </div>

            {/* <!-- All menu : web --> */}
            <div className="all_menu WEB closed">
                <h2 className="blind">전체메뉴</h2>
                <div className="inner">
                    <div className="col">
                        <h3>법인소개</h3>
                        <ul>
                            <li><NavLink to={URL.ABOUT_SITE} className={({ isActive }) => (isActive ? "cur" : "")}>소개</NavLink></li>
                            <li><NavLink to={URL.ABOUT_HISTORY} className={({ isActive }) => (isActive ? "cur" : "")}>연혁</NavLink></li>
                            <li><NavLink to={URL.ABOUT_ORGANIZATION} className={({ isActive }) => (isActive ? "cur" : "")}>조직소개</NavLink></li>
                            <li><NavLink to={URL.ABOUT_LOCATION} className={({ isActive }) => (isActive ? "cur" : "")}>찾아오시는 길</NavLink></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>기관소개 및 사업계획서</h3>
                        <ul>
                            <li><NavLink to={URL.INTRO_SERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>기관 소개</NavLink></li>
                            <li><Link to="https://kpoplove58.tistory.com/" className={({ isActive }) => (isActive ? "cur" : "")}>아가페지역아동센터</Link></li>
                            <li><Link to="https://kpoplove58.tistory.com/" className={({ isActive }) => (isActive ? "cur" : "")}>사랑나눔지역아동센터</Link></li>
                            <li><Link to="https://blog.naver.com/sangnokdadol" className={({ isActive }) => (isActive ? "cur" : "")}>상록가치키움터</Link></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>고객지원</h3>
                        <ul>
                            <li><NavLink to={URL.SUPPORT_DOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")}>운영자료</NavLink></li>
                            <li><NavLink to={URL.SUPPORT_APPLY} className={({ isActive }) => (isActive ? "cur" : "")}>후원 문의</NavLink></li>
                        </ul>
                    </div>
                    
                    <div className="col">
                        <h3>알림마당</h3>
                        <ul>
                            <li><NavLink to={URL.INFORM_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")}>공지사항</NavLink></li>
                            <li><NavLink to={URL.INFORM_GALLERY} className={({ isActive }) => (isActive ? "cur" : "")}>사이트 갤러리</NavLink></li>
                        </ul>
                    </div>
                    {sessionUserId &&
                        <div className="col">
                            <h3>사이트관리</h3>
                            <ul>
                                <li><NavLink to={URL.ADMIN_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")}>공지사항관리</NavLink></li>
                                <li><NavLink to={URL.ADMIN_GALLERY} className={({ isActive }) => (isActive ? "cur" : "")}>갤러리관리</NavLink></li>
                                <li><NavLink to={URL.ADMIN_DOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")}>운영자료관리</NavLink></li>
								<li><NavLink to={URL.ADMIN_MANAGER} className={({ isActive }) => (isActive ? "cur" : "")}>사이트관리자 암호변경</NavLink></li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
            {/* <!-- All menu : mobile --> */}
            <div className="all_menu Mobile closed">
                <div className="user_info_m">
                    {/* 로그아웃 : 로그인 정보 있을때 */}
                    {sessionUserId &&
                        <>
                            <span className="person">{sessionUserName} </span>이 로그인하셨습니다.
                            <button onClick={logOutHandler} className="btn logout">로그아웃</button>
                        </>
                    }

                    {/* 로그인 : 로그인 정보 없을 때 */}
                    {!sessionUserId &&
                        <button onClick={logInHandler} className="btn login">로그인</button>
                    }
                    <button className="btn noscript close" type="button">전체메뉴 닫기</button>
                </div>
                <div className="menu">
                    <h3><Link to={URL.ABOUT}>법인소개</Link></h3>
                    <div className="submenu closed">
                        <ul>
                            <li><NavLink to={URL.ABOUT_SITE} className={({ isActive }) => (isActive ? "cur" : "")}>소개</NavLink></li>
                            <li><NavLink to={URL.ABOUT_HISTORY} className={({ isActive }) => (isActive ? "cur" : "")}>연혁</NavLink></li>
                            <li><NavLink to={URL.ABOUT_ORGANIZATION} className={({ isActive }) => (isActive ? "cur" : "")}>조직소개</NavLink></li>
                            <li><NavLink to={URL.ABOUT_LOCATION} className={({ isActive }) => (isActive ? "cur" : "")}>찾아오시는 길</NavLink></li>
                        </ul>
                    </div>
                    <h3><Link to={URL.INTRO_SERVICE}>기관소개 및 사업계획서</Link></h3>
                    <div className="submenu closed">
                        <ul>
                            <li><NavLink to={URL.INTRO_SERVICE} className={({ isActive }) => (isActive ? "cur" : "")}>기관 소개</NavLink></li>
                            <li><Link to="https://kpoplove58.tistory.com/" className={({ isActive }) => (isActive ? "cur" : "")}>아가페지역아동센터</Link></li>
                            <li><Link to="https://kpoplove58.tistory.com/" className={({ isActive }) => (isActive ? "cur" : "")}>사랑나눔지역아동센터</Link></li>
                            <li><Link to="https://blog.naver.com/sangnokdadol" className={({ isActive }) => (isActive ? "cur" : "")}>상록가치키움터</Link></li>
                        </ul>
                    </div>
                    <h3><Link to={URL.SUPPORT}>고객지원</Link></h3>
                    <div className="submenu closed">
                        <ul>
                            <li><NavLink to={URL.SUPPORT_DOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")}>운영자료</NavLink></li>
                            <li><NavLink to={URL.SUPPORT_APPLY} className={({ isActive }) => (isActive ? "cur" : "")}>후원 안내</NavLink></li>
                        </ul>
                    </div>
                    <h3><Link to={URL.INFORM_NOTICE}>알림마당</Link></h3>
                    <div className="submenu closed">
                        <ul>
                            <li><NavLink to={URL.INFORM_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")}>공지사항</NavLink></li>
                            <li><NavLink to={URL.INFORM_GALLERY} className={({ isActive }) => (isActive ? "cur" : "")}>사이트 갤러리</NavLink></li>
                        </ul>
                    </div>
                    {sessionUserId &&
                        <>
                            <h3><Link to={URL.ADMIN}>사이트관리</Link></h3>
                            <div className="submenu closed">
                                <ul>
                                    <li><NavLink to={URL.ADMIN_NOTICE} className={({ isActive }) => (isActive ? "cur" : "")}>공지사항관리</NavLink></li>
                                    <li><NavLink to={URL.ADMIN_GALLERY} className={({ isActive }) => (isActive ? "cur" : "")}>갤러리관리</NavLink></li>
                                    <li><NavLink to={URL.ADMIN_DOWNLOAD} className={({ isActive }) => (isActive ? "cur" : "")}>운영자료관리</NavLink></li>
									<li><NavLink to={URL.ADMIN_MANAGER} className={({ isActive }) => (isActive ? "cur" : "")}>사이트관리자 암호변경</NavLink></li>
                                </ul>
                            </div>
                        </>
                    }
                </div>
            </div>
            {/* <!--// All menu --> */}
        </div>
        // <!--// header -->
    );
}

export default EgovHeader;