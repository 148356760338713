import React from 'react';

import { Link } from 'react-router-dom';
import { default as EgovLeftNav } from 'components/leftmenu/EgovLeftNavIntro';

function EgovIntroService() {
    return (
        <div className="container">
        <div className="c_wrap">
            {/* <!-- Location --> */}
            <div className="location">
                <ul>
                    <li><a className="home" href="#!">Home</a></li>
                    <li><a href="#!">기관소개 및 사업계획서</a></li>
                    <li>기관 소개</li>
                </ul>
            </div>
            {/* <!--// Location --> */}

            <div className="layout">
                {/* <!-- Navigation --> */}
                <EgovLeftNav></EgovLeftNav>
                {/* <!--// Navigation --> */}
                
                <div className="contents SERVICE_INTRO" id="contents">
                    {/* <!-- 본문 --> */}

                    <h1 className="tit_3">기관소개 및 사업계획서</h1>

                    <p className="txt_1">아름다운 세상 협동 조합의 하위 기관들이 소개된 페이지입니다.</p>
                    
                    <h2 className="tit_4">기관 소개</h2>

                    <p className="txt_1">
                        아름다운 세상 사회적 협동조합은
                        2곳의 지역 아동센터와 1곳의 다함께돌봄센터으로 구성되어 서비스를 제공합니다.<br/>
                                            </p>

                    
                    <h3 className="tit_5">아가페 지역 아동센터</h3>

                    <div className="msg">
                        <p>아가페 지역 아동센터는 꿈과 희망이 있는 사랑나눔터입니다.<br/></p>
                        <ul>
                            <li>주소 : 안산시 상록구 일동로 15 2층</li>
                            <li>전화 : 031-419-8122</li>
                            <li>운영 시간 : 학기중 09:00~19:00 (간식 및 석식 제공), 방학중 09:00~19:00 ( 중식, 간식, 석식 제공)</li>
                            <li>2007년 9월에 개소한 49인 시설로 일동 지역사회 내 보호를 필요로 하는 만 18세 미만 아동을 대상으로 종합적인 아동복지 서비스를 제공합니다. </li>
                            <li>보호자들과 상담을 통하여 욕구를 파악하고 아동의 특성에 맞는 교육과 특기 활동을 제공하여 아동들이 안전하고 즐겁게 이용하는 공간을 만들어가고자 합니다.</li>
                            <Link to="https://kpoplove58.tistory.com/"><li>아가페 지역 아동센터 자세히 보기 (클릭)</li></Link>
                        </ul>
                    </div>

                    <h3 className="tit_5">사랑나눔 지역 아동센터</h3>

                    <div className="msg second">
                        <p>사랑나눔 지역아동센터는 안산시 상록구 사동에 위치한 아동센터입니다.<br/></p>
                        <ul>
                            <li>주소 : 안산시 상록구 후곡로  34</li>
                            <li>전화 : 031-438-1255</li>
                            <li>운영 시간 : 학기중 09:00~19:00 (간식 및 석식 제공), 방학중 08:30~19:00 ( 중식, 간식, 석식 제공)</li>
                            <li>방과 후 안전한 돌봄과 급,간식 제공, 기초학습, 음악 활동, 종이접기, 체육 등 교육 활동, 놀이와 캠프 등 문화 활동을 제공하여 아동의 건전한 성장을 돕고 맞벌이 가정 등에서 안심하고 일할 수 있는 환경을 제공하고 있는 아동복지시설입니다.</li>
                            <Link to="https://kpoplove58.tistory.com/"><li>사랑나눔 지역 아동센터 자세히 보기 (클릭)</li></Link>
                        </ul>
                    </div>

                    <h3 className="tit_5">안산시다함께돌봄센터(상록가치키움터)</h3>

                    <div className="msg third">
                        <p>상록가치키움터는 안산시 아동 & 학부모의 열린공간입니다.<br/></p>
                        <ul>
                            <li>주소 : 경기도 안산시 상록구 각골로7길 9</li>
                            <li>전화 : 031-406-2023</li>
                            <li>운영 시간 : 학기중 13:00~19:00,  방학중 09:00~19:00</li>
                            <li>방과 후 안전한 돌봄과 급,간식 제공, 기초학습, 음악 활동, 종이접기, 체육 등 교육 활동, 놀이와 캠프 등 문화 활동을 제공하여 아동의 건전한 성장을 돕고 맞벌이 가정 등에서 안심하고 일할 수 있는 환경을 제공하고 있는 아동복지시설입니다.</li>
                            <Link to="https://blog.naver.com/sangnokdadol"><li>상록가치키움터 자세히 보기 (클릭)</li></Link>
                        </ul>
                    </div>

                    {/* <!--// 본문 --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default EgovIntroService;